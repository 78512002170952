import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  PhoneTextField,
} from '@beeinventor/dasiot-react-component-lib';
import {
  Avatar as MuiAvatar,
  Box,
  styled,
  TextareaAutosize,
  Typography,
} from '@mui/material';

import { InputValidationError, WorkerWillAssign } from '../../../types';

import { useAppSelector } from '../../../hooks';
import usePhone from '../../../hooks/usePhone';

import WorkerDefault from '../../../assets/images/default-image/worker_default.png';
import UploadSvg from '../../../assets/images/svg/btn_upload_image.svg';
import ErrorSvg from '../../../assets/images/svg/ic_error_ifo.svg';
import { BIRTHDAY_LIST, PHONE_LIST } from '../../../constant/constants';
import MuiTheme from '../../../theme';
import checkEmailPassEmpty from '../../../utils/checkEmailPassEmpty';
import OrgAutocompleteBasic from '../../OrgAutocompleteBasic';
import Input from '../../TextField/Input';

import './TargetOperationDialog.css';

const Container = styled('div')`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled('div')`
  flex: 1;
  overflow-y: auto;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0 10px 10px 10px;
  border-radius: 4px;
`;

const ContentTitle = styled(Typography)`
  color: white;
`;

const SpanRequire = styled('span')`
  color: ${({ theme }) => theme.color.highlight};
`;

const Avatar = styled(MuiAvatar)`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin: 0 auto;
`;

const ContainerDetails = styled('div')`
  margin: 10px 0;
  height: 58px;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.box_bbg,
}));

const ContainerImage = styled('div')`
  margin: 10px auto;
  width: 200px;
  position: relative;
`;

const ContainerUploadImage = styled('div')`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
`;

const Error = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  & > span {
    color: #ff6b00;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

const ContainerDropdown = styled('div')`
  & > .Dropdown-root {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    padding: 0 8px;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }

    & > .Dropdown-icon {
      width: 32px;
      height: 32px;
    }
  }
  & > .Dropdown--disabled {
    background: #e5e5e5;
    color: ${({ theme }) => theme.color.secondary.$80};
  }
  & div[role='tooltip'] {
    & > .MuiBox-root {
      color: ${({ theme }) => theme.color.box_bbg};
      font-weight: 200;
      font-size: 12px;
      background: ${({ theme }) => theme.color.secondary.$100};
      max-height: 150px;
      overflow: auto;
    }
    z-index: 1;
  }
`;

const ContainerTextArea = styled('div')`
  & textarea {
    ${({ theme }) => ({ ...theme.typography.body2 })}
    width: 100%;
    font-family: 'Noto Sans TC', 'Noto Sans';
    background: ${({ theme }) => theme.color.secondary.$100};
    border-radius: 4px;
    outline: none;
    color: white;
    padding: 10px;
    border-color: transparent;
    resize: none;
    &:hover {
      background: ${({ theme }) => theme.color.secondary.$100};
    }
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  }
`;

const getYearFromDate = (dateString: string) => {
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  return year.toString();
};

interface WorkerBasicInformationContentProps {
  handleWorkerDataWillAssign: (workerData: WorkerWillAssign) => void;
  data: WorkerWillAssign | undefined;
}

const WorkerBasicInformationContent: React.FC<
  WorkerBasicInformationContentProps
> = ({ handleWorkerDataWillAssign, data }) => {
  const { t } = useTranslation('project-setting');
  const fileRef = useRef<HTMLInputElement | null>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const [imageFormatError, setImageFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });
  const [workerNameFormatError, setWorkerNameFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [emailFormatError, setEmailFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [phoneFormatError, setPhoneFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [orgIdError, setOrgIdError] = useState<InputValidationError>({
    isError: false,
    message: '',
  });
  const [focusPhoneInput, setFocusPhoneInput] = useState(false);

  const handleOnChange = (
    propertyObjs: Array<{ propertyName: keyof WorkerWillAssign; value: any }>,
  ) => {
    const currentValue = {
      ...data,
    };
    propertyObjs.forEach((obj) => {
      // @ts-ignore
      currentValue[obj.propertyName] = obj.value;
    });
    handleWorkerDataWillAssign({
      name: currentValue?.name ?? '',
      imageURL: currentValue?.imageURL ?? '',
      gender: currentValue?.gender,
      birthday: currentValue?.birthday ?? undefined,
      email: currentValue?.email ?? undefined,
      phone: currentValue?.phone ?? '',
      division: currentValue?.division ?? undefined,
      trade: currentValue?.trade ?? undefined,
      registrationNumber: currentValue?.registrationNumber,
      groupIds: currentValue?.groupIds ?? [],
      remark: currentValue?.remark,
      orgId: currentValue?.orgId ?? '',
      id: currentValue?.id ?? '',
      avatarFile: currentValue.avatarFile,
      subContractorName: currentValue.subContractorName,
    });
  };

  const handleOnClickUploadImage = () => {
    fileRef.current?.click();
  };

  const handleOnSelectFile = () => {
    setImageFormatError({ isError: false, message: '' });
    if (fileRef.current && fileRef.current?.files) {
      const fileImge = fileRef.current?.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const newSrc = reader.result as string;
        if (fileRef.current && fileRef.current.files) {
          handleOnChange([
            {
              propertyName: 'avatarFile',
              value: fileRef.current.files[0],
            },
            {
              propertyName: 'imageURL',
              value: newSrc,
            },
          ]);
        }
      });
      reader.readAsDataURL(fileImge);
    }
  };

  const checkWorkerNameFormat = () => {
    if (data?.name === '') {
      return setWorkerNameFormatError({
        isError: true,
        message: t('error:inputIsEmpty'),
      });
    }
    return setWorkerNameFormatError({ isError: false, message: '' });
  };

  const handleEmailFormat = () => {
    setEmailFormatError(checkEmailPassEmpty(data?.email ?? ''));
  };

  const checkPhoneFormat = () => {
    if (
      !/^(\+?\d{1,3}|\d{1,4})\d*/.test(data?.phone ?? '') &&
      data?.phone !== ''
    ) {
      return setPhoneFormatError({
        isError: true,
        message: t('error:notPhone'),
      });
    }

    return setPhoneFormatError({
      isError: false,
      message: '',
    });
  };

  useEffect(() => {
    if (!data) {
      handleWorkerDataWillAssign({
        id: '',
        imageURL: null,
        name: '',
        gender: undefined,
        birthday: undefined,
        phone: '',
        division: undefined,
        trade: undefined,
        email: undefined,
        groupIds: [],
        remark: undefined,
        orgId: '',
        subContractorName: '',
      });
    }
  }, [data]);

  const LIST_GENDER = useMemo(() => {
    return [
      {
        id: 'male',
        value: 'male',
        name: t(
          'page.worker-management.dialog.create-worker.basic-information.gender-male',
        ),
      },
      {
        id: 'female',
        value: 'female',
        name: t(
          'page.worker-management.dialog.create-worker.basic-information.gender-female',
        ),
      },
    ];
  }, [t]);

  const LIST_ORG = useMemo(() => {
    const orgs = Object.values(orgMap);
    if (orgs.length > 0) {
      return orgs.map((organization) => ({
        id: organization?.id ?? '',
        name: organization?.name ?? '',
        color: organization?.color ?? '',
      }));
    }
    return [
      {
        id: '',
        name: '',
        color: '',
      },
    ];
  }, [orgMap]);

  const { code, phone } = usePhone(data?.phone);

  return (
    <Container ref={dialogRef}>
      <ContentTitle>
        {t(
          'page.worker-management.dialog.create-worker.basic-information.title',
        )}
        <SpanRequire>*</SpanRequire>
      </ContentTitle>

      <Content>
        <ContainerImage>
          <Avatar
            src={data?.imageURL ?? ''}
            alt="worker photo"
            sx={{ marginBottom: '10px' }}
            variant="square"
          >
            <Avatar src={WorkerDefault} alt="worker beeinventor" />
          </Avatar>
          <ContainerUploadImage onClick={handleOnClickUploadImage}>
            <img src={UploadSvg} alt="upload icon" />
            <input
              ref={fileRef}
              type="file"
              hidden
              accept=".jpg, .png, .jpeg, .svg"
              onChange={handleOnSelectFile}
              data-cy="input-image-worker"
            />
          </ContainerUploadImage>
        </ContainerImage>
        {imageFormatError.message && imageFormatError.message !== '' && (
          <Error>
            {<img src={ErrorSvg} alt="error" />}
            <span>{imageFormatError.message}</span>
          </Error>
        )}
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.basic-information.organization',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <OrgAutocompleteBasic
            data={LIST_ORG}
            onSelect={(d) => {
              handleOnChange([{ propertyName: 'orgId', value: d.id }]);
            }}
            onBlur={() => {
              if (!data?.orgId) {
                setOrgIdError({ isError: true, message: t('error') });
              } else {
                setOrgIdError({ isError: false, message: t('') });
              }
            }}
            error={orgIdError}
            selectedId={data?.orgId}
            data-cy="dropdown-org-worker"
            placeholder={t('page.select-organization')}
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.basic-information.worker-name',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <Input
            name={t(
              'page.worker-management.dialog.create-worker.basic-information.worker-name',
            )}
            value={data?.name ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'name',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            onBlur={checkWorkerNameFormat}
            error={workerNameFormatError.isError}
            errorMessage={workerNameFormatError.message}
            autoComplete="off"
            data-cy="input-name-worker"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.basic-information.gender',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <ContainerDropdown>
            <Dropdown
              list={LIST_GENDER}
              onSelect={(v) => {
                handleOnChange([
                  {
                    propertyName: 'gender',
                    value: v,
                  },
                ]);
              }}
              popperProps={{ disablePortal: true }}
              selectedId={data?.gender}
              placeholder={t(
                'page.worker-management.dialog.create-worker.basic-information.select-gender',
              )}
              data-cy="dropdown-gender-worker"
            />
          </ContainerDropdown>
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.basic-information.birth-year',
            )}
          </Title>
          <ContainerDropdown>
            <Dropdown
              list={BIRTHDAY_LIST}
              onSelect={(v) => {
                handleOnChange([
                  {
                    propertyName: 'birthday',
                    value: v,
                  },
                ]);
              }}
              popperProps={{ disablePortal: true }}
              selectedId={
                data?.birthday ? getYearFromDate(data?.birthday) : undefined
              }
              placeholder={t('select-birth-year')}
            />
          </ContainerDropdown>
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.basic-information.registration-number',
            )}
          </Title>
          <Input
            name={t(
              'page.worker-management.dialog.create-worker.basic-information.registration-number',
            )}
            value={data?.registrationNumber ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'registrationNumber',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            autoComplete="off"
            data-cy="input-registration-number-worker"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>{t('company')}</Title>
          <Input
            name={t('company')}
            value={data?.subContractorName ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'subContractorName',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            autoComplete="off"
            data-cy="input-subcontractor-worker"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.basic-information.email',
            )}
          </Title>
          <Input
            name={t(
              'page.worker-management.dialog.create-worker.basic-information.email',
            )}
            value={data?.email ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'email',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            onBlur={handleEmailFormat}
            error={emailFormatError.isError}
            errorMessage={emailFormatError.message}
            autoComplete="off"
            data-cy="input-email-worker"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.basic-information.phone',
            )}
          </Title>
          <PhoneTextField
            value={phone}
            rootProps={{
              className: 'input',
              sx: {
                '& > .MuiBox-root:nth-of-type(1)': {
                  height: '40px',
                  minHeight: '40px',
                  background: '#323232',
                  padding: 0,
                  border: focusPhoneInput
                    ? `1px solid ${MuiTheme.externalColor.dark.$20}`
                    : `1px solid ${
                        phoneFormatError.isError ? 'default' : 'transparent'
                      }`,
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.3)',
                  },
                  '& > .MuiBox-root': {
                    alignItems: 'center',
                    '& > .MuiBox-root': {
                      height: `calc(100% - 20px)`,
                      width: '1px',
                    },
                  },
                },
              },
            }}
            popperProps={{
              disablePortal: false,
              className: 'dropdown-phone',
            }}
            inputProps={{
              autoComplete: 'off',
              onFocus: () => {
                setFocusPhoneInput(true);
              },
              onBlur: () => {
                checkPhoneFormat();
                setFocusPhoneInput(false);
              },
            }}
            countryCode={code}
            countryCodeList={PHONE_LIST}
            onChange={(v) => {
              handleOnChange([
                {
                  propertyName: 'phone',
                  value: `+${v.code}${v.phone.replace(/^0/, '')}`,
                },
              ]);
            }}
            error={phoneFormatError.isError}
            errorMessage={phoneFormatError.message}
            data-cy="input-phone-worker"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.basic-information.trade',
            )}
          </Title>
          <Input
            name={t(
              'page.worker-management.dialog.create-worker.basic-information.trade',
            )}
            value={data?.trade ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'trade',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            autoComplete="off"
            data-cy="input-trade-worker"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.basic-information.division',
            )}
          </Title>
          <Input
            name={t(
              'page.worker-management.dialog.create-worker.basic-information.division',
            )}
            value={data?.division ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'division',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            autoComplete="off"
            data-cy="input-division-worker"
          />
        </ContainerDetails>
        <ContainerTextArea>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.basic-information.remark',
            )}
          </Title>
          <TextareaAutosize
            id="group-remark-input"
            name="group-remark"
            value={data?.remark ?? ''}
            maxRows={4}
            minRows={4}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'remark',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            autoComplete="off"
            data-cy="input-remark-worker"
          />
        </ContainerTextArea>
      </Content>
    </Container>
  );
};

export default WorkerBasicInformationContent;
