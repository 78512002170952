import { useContext, useEffect, useState } from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';

import { DascasType, DataPoint } from '../../types';
import { Dascas, DascasG } from '../../types/Device';
import {
  WebSocketCoordinates,
  WebSocketDascasDataPoint,
} from '../../types/Websocket';

import { getProjectDascas, getUserDascas } from '../../apis/DascasApi';

import { PubSubContext } from '../../utils/PubSub';

type UseProjectDascasMapParams = {
  queryKey: QueryKey;
  projectId: string | null | undefined;
  refetchInterval?: number;
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
  indexBy?: 'id' | 'dasId';
  type: DascasType;
};

type DascasMap<T> = { [id: string]: T | undefined };

export const useIdDascasMap = <T extends Dascas | DascasG>({
  queryKey,
  projectId,
  refetchInterval,
  refetchOnWindowFocus,
  enabled = true,
  indexBy = 'id',
  type,
}: UseProjectDascasMapParams) => {
  const pubSub = useContext(PubSubContext);
  const [deviceMap, setDeviceMap] = useState<DascasMap<T>>({});

  const { data, ...others } = useQuery({
    queryKey,
    queryFn: async () => {
      let local: T[] = [];

      const load = async (nextCursor?: string) => {
        const res = await (projectId
          ? getProjectDascas<T>({
              projectId: projectId,
              params: {
                type: type,
                nextCursor: nextCursor,
              },
            })
          : getUserDascas<T>({
              nextCursor: nextCursor,
              type: type,
            }));

        local = local.concat(res.data.data);

        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };

      await load();

      return local.reduce(
        (prev, curr) => {
          if (indexBy === 'id') {
            prev[curr.id] = curr;
          } else {
            prev[curr.dasId] = curr;
          }
          return prev;
        },
        {} as Record<string, T>,
      );
    },
    enabled: projectId ? !!projectId : enabled,
    refetchInterval,
    refetchOnWindowFocus,
  });

  useEffect(() => {
    const dataPointHandler = (datapointData: WebSocketDascasDataPoint) => {
      if (projectId === datapointData.endpoint.metadata['dsm/projectId']) {
        setDeviceMap((currentDeviceMap) => {
          let index = '';
          if (indexBy === 'id') {
            index = datapointData.endpoint.id;
          } else if (indexBy === 'dasId') {
            index = datapointData.endpoint.dasId;
          }
          const device = currentDeviceMap[index];
          const updateDataPoint: { [key: string]: DataPoint<any> } =
            Object.entries(datapointData.dataPoint).reduce(
              (prev, [key, value]) => {
                if (value !== undefined || value !== null) {
                  prev[key] = {
                    timestamp: datapointData.timestamp,
                    value,
                    stale: false,
                  };
                }
                return prev;
              },
              {},
            );
          if (device) {
            return {
              ...currentDeviceMap,
              [datapointData.endpoint.id]: {
                ...device,
                shadow: {
                  dataPoint: {
                    ...device.shadow.dataPoint,
                    ...updateDataPoint,
                  },
                },
              },
            };
          }

          return currentDeviceMap;
        });
      }
    };

    const coordinatesHandler = (coordinatesData: WebSocketCoordinates) => {
      if (projectId === coordinatesData.endpoint.metadata['dsm/projectId']) {
        setDeviceMap((currentDeviceMap) => {
          const device = currentDeviceMap[coordinatesData.endpoint.id];

          if (device) {
            return {
              ...currentDeviceMap,
              [coordinatesData.endpoint.id]: {
                ...device,
                coordinates: coordinatesData.coordinates,
                coordinatesSource: coordinatesData.source,
                lastCoordinatesUpdatedAt: coordinatesData.timestamp,
              },
            };
          }

          return currentDeviceMap;
        });
      }
    };

    pubSub?.subscribe('dascas_g:data-point-received', dataPointHandler);
    pubSub?.subscribe('coordinates-updated', coordinatesHandler);
    return () => {
      pubSub?.unsubscribe('dascas_g:data-point-received', dataPointHandler);
      pubSub?.unsubscribe('coordinates-updated', coordinatesHandler);
    };
  }, [pubSub, projectId, indexBy]);

  useEffect(() => {
    setDeviceMap(data ?? {});
  }, [data]);

  return {
    data: deviceMap,
    ...others,
  };
};
