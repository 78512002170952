import { useContext, useEffect, useRef, useState } from 'react';
import { QueryKey, useInfiniteQuery } from '@tanstack/react-query';

import { DataPoint } from '../../types';
import { Dasloop } from '../../types/Device';
import {
  WebSocketCoordinates,
  WebSocketDasloopDataPoint,
} from '../../types/Websocket';

import { getProjectDasloops } from '../../apis/DasloopApi';

import { PubSubContext } from '../../utils/PubSub';

type UseProjectDasloopMapParams = {
  queryKey: QueryKey;
  projectId: string | null | undefined;
  indexBy?: 'id' | 'dasId';
  orderBy?: 'das_id' | 'last_data_point_received_at';
  queryOptions?: {
    enabled?: boolean;
  };
  onProgress?: (progress) => void;
};

type DasloopMap = { [id: string]: Dasloop | undefined };

/* Important:
 * Plese use this hook with follow to get all devices
 *  useEffect(() => {
 *   let timer: NodeJS.Timeout;
 *  if (isFetchingDasloopMap === false && hasNextPage) {
 *    timer = setTimeout(() => {
 *      fetchNextPage();
 *    }, 200);
 *
 *    return () => {
 *      clearTimeout(timer);
 *    };
 *  }
 * }, [hasNextPage, isFetchingDasloopMap]);
 * Otherwise it will let queue size bigger and bigger and do not update the deveices
 */

export const useProjectDasLoopMapWithPages = ({
  projectId,
  indexBy = 'id',
  orderBy,
  queryKey,
  queryOptions,
  onProgress,
}: UseProjectDasloopMapParams) => {
  const dataPointQueueRef = useRef<WebSocketDasloopDataPoint[]>([]);
  const coordinatesQueueRef = useRef<WebSocketCoordinates[]>([]);
  const pubSub = useContext(PubSubContext);
  const [dasLoopMap, setDasLoopMap] = useState<DasloopMap>({});
  const [total, setTotal] = useState(0);

  const { data, ...others } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const res = await getProjectDasloops({
        projectId: projectId as string,
        params: {
          orderBy,
          nextCursor: pageParam,
        },
      });
      setTotal((origin) => {
        if (origin !== res.data.metadata.total) {
          return res.data.metadata.total;
        }

        return origin;
      });
      return res.data;
    },
    getNextPageParam: (res) => res.paging.nextCursor || undefined,
    enabled: !!projectId && (queryOptions?.enabled ?? true),
    refetchOnWindowFocus: false,
    initialPageParam: undefined,
  });

  useEffect(() => {
    if (data) {
      const newDasLoopMap: DasloopMap = {};
      let count = 0;
      data.pages
        .map((page) => page.data)
        .flat()
        .forEach((dasLoop) => {
          count++;
          if (indexBy === 'dasId') {
            newDasLoopMap[dasLoop.dasId] = dasLoop;
          } else if (indexBy === 'id') {
            newDasLoopMap[dasLoop.id] = dasLoop;
          }
        });
      setDasLoopMap((origin) => ({ ...newDasLoopMap, ...origin }));
      onProgress?.((count / (total == 0 ? 1 : total)) * 100);
    }
  }, [data, indexBy, total]);

  useEffect(() => {
    const dataPointHandler = (datapointData: WebSocketDasloopDataPoint) => {
      if (projectId === datapointData.endpoint.metadata['dsm/projectId']) {
        dataPointQueueRef.current.push(datapointData);
      }
    };

    const coordinatesHandler = (coordinatesData: WebSocketCoordinates) => {
      if (projectId === coordinatesData.endpoint.metadata['dsm/projectId']) {
        coordinatesQueueRef.current.push(coordinatesData);
      }
    };

    pubSub?.subscribe('dasloop:data-point-received', dataPointHandler);
    pubSub?.subscribe('coordinates-updated', coordinatesHandler);
    return () => {
      pubSub?.unsubscribe('dasloop:data-point-received', dataPointHandler);
      pubSub?.unsubscribe('coordinates-updated', coordinatesHandler);
    };
  }, [pubSub, projectId, indexBy]);

  useEffect(() => {
    const dataTotal = data?.pages.map((page) => page.data).flat().length ?? 0;

    if (dataTotal === total && total !== 0) {
      const dataPointQueueTimer = setInterval(() => {
        const dataPoints: WebSocketDasloopDataPoint[] = [];

        while (dataPointQueueRef.current.length > 0) {
          const newDataPoint =
            dataPointQueueRef.current.shift() as WebSocketDasloopDataPoint;
          dataPoints.push(newDataPoint);
        }

        if (dataPoints.length > 0) {
          setDasLoopMap((origin) => {
            const newDasLoopMap = { ...origin };
            dataPoints
              .filter((datapointData) => {
                return !!origin[datapointData.endpoint[indexBy]];
              })
              .map((datapointData) => {
                const newDasLoop = origin[datapointData.endpoint[indexBy]];

                if (newDasLoop) {
                  const updateDataPoint: { [key: string]: DataPoint<any> } =
                    Object.entries(datapointData.dataPoint).reduce(
                      (prev, [key, value]) => {
                        if (value !== undefined || value !== null) {
                          prev[key] = {
                            timestamp: datapointData.timestamp,
                            value,
                            stale: false,
                          };
                        }
                        return prev;
                      },
                      {},
                    );

                  newDasLoop.shadow.dataPoint = {
                    ...newDasLoop.shadow.dataPoint,
                    ...updateDataPoint,
                  };

                  newDasLoopMap[newDasLoop[indexBy]] = newDasLoop;
                }
              });

            return newDasLoopMap;
          });
        }
      }, 1000);

      const coordinatesQueueTimer = setInterval(() => {
        const coords: WebSocketCoordinates[] = [];

        while (coordinatesQueueRef.current.length > 0) {
          const webSocketCoordinates =
            coordinatesQueueRef.current.shift() as WebSocketCoordinates;
          coords.push(webSocketCoordinates);
        }

        if (coords.length > 0) {
          setDasLoopMap((origin) => {
            const newDasLoopMap = { ...origin };
            coords
              .filter((coord) => {
                return !!origin[coord.endpoint[indexBy]];
              })
              .map((coord) => {
                const newDasLoop = origin[coord.endpoint[indexBy]];

                if (newDasLoop) {
                  newDasLoop.coordinates = coord.coordinates;
                  newDasLoop.coordinatesSource = coord.source;
                  newDasLoop.lastCoordinatesUpdatedAt = coord.timestamp;
                  newDasLoopMap[newDasLoop[indexBy]] = newDasLoop;
                }
              });

            return newDasLoopMap;
          });
        }
      }, 1000);

      return () => {
        clearInterval(dataPointQueueTimer);
        clearInterval(coordinatesQueueTimer);
      };
    }
  }, [data, total, indexBy]);

  return {
    data: dasLoopMap,
    ...others,
  };
};
