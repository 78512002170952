import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Auth } from '../types';

import { parseJwt } from '../utils/Auth';

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  expiredDate: number;
  refreshTokenExpiredDate: number;
  accessTokenObj: { email: string } | null;
  isUserVerified: boolean;
}

const initial = (): AuthState => {
  const accessToken = localStorage.getItem('accessToken') || null;

  return {
    accessToken,
    refreshToken: localStorage.getItem('refreshToken') || null,
    expiredDate: parseInt(localStorage.getItem('expiredDate') || '0', 10),
    refreshTokenExpiredDate: parseInt(
      localStorage.getItem('refreshTokenExpiredDate') || '0',
      10,
    ),
    accessTokenObj: accessToken ? parseJwt(accessToken) : null,
    isUserVerified: false,
  };
};

const initialState: AuthState = initial();

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<Auth>) => {
      localStorage.setItem('accessToken', action.payload.access_token);
      localStorage.setItem('refreshToken', action.payload.refresh_token);
      const expiredDate = Date.now() + action.payload.expires_in * 1000;
      const refreshTokenExpiredDate =
        Date.now() + action.payload.refresh_expires_in * 1000;
      localStorage.setItem('expiredDate', expiredDate.toString());
      localStorage.setItem(
        'refreshTokenExpiredDate',
        refreshTokenExpiredDate.toString(),
      );
      return {
        accessToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        expiredDate,
        refreshTokenExpiredDate,
        accessTokenObj: parseJwt(action.payload.access_token),
        isUserVerified: action.payload.isUserVerified,
      };
    },
    setAuth: (
      state,
      action: PayloadAction<Required<Omit<AuthState, 'accessTokenObj'>>>,
    ) => {
      if (action.payload.accessToken) {
        localStorage.setItem('accessToken', action.payload.accessToken);
      } else {
        localStorage.removeItem('accessToken');
      }
      if (action.payload.refreshToken) {
        localStorage.setItem('refreshToken', action.payload.refreshToken);
      } else {
        localStorage.removeItem('refreshToken');
      }
      if (action.payload.expiredDate) {
        localStorage.setItem(
          'expiredDate',
          action.payload.expiredDate.toString(),
        );
      } else {
        localStorage.removeItem('expiredDate');
      }
      if (action.payload.refreshTokenExpiredDate) {
        localStorage.setItem(
          'expirerefreshTokenExpiredDatedDate',
          action.payload.refreshTokenExpiredDate.toString(),
        );
      } else {
        localStorage.removeItem('expirerefreshTokenExpiredDatedDate');
      }

      return {
        ...state,
        ...action.payload,
      };
    },
    setUserVerified: (state, action: PayloadAction<boolean>) => {
      return { ...state, isUserVerified: action.payload };
    },
    logout: (state) => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshTokenExpiredDate');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('expiredDate');
      localStorage.removeItem('selectedProjectId');
      localStorage.removeItem('isUserVerified');
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        accessTokenObj: null,
        isUserVerified: false,
      };
    },
  },
});

export const { login, setAuth, logout, setUserVerified } = authSlice.actions;

export default authSlice.reducer;
