import { StrictMode, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { polyfill } from 'mobile-drag-drop';

import PubSub, { PubSubContext } from '../src/utils/PubSub';

import { initialWindowMessageListener } from './utils/windowMessageListener';
import AppRouter from './AppRouter';
import store from './store';
import theme from './theme';

polyfill();
window.addEventListener('touchmove', function () {});
initialWindowMessageListener();

const queryClient = new QueryClient();

const Main = () => {
  const pubSubRef = useRef<PubSub>(new PubSub());
  useEffect(() => document.getElementById('init-loading')?.remove(), []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles
            styles={{
              '::-webkit-scrollbar': {
                width: '1em',
              },

              '::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },

              '::-webkit-scrollbar-thumb': {
                backgroundClip: 'content-box',
                border: '5px solid transparent',
                backgroundColor: '#a1a1a1',
                borderRadius: '1rem',
              },

              '::-webkit-scrollbar-corner': {
                background: 'transparent',
              },
            }}
          />
          <PubSubContext.Provider value={pubSubRef.current}>
            <AppRouter />
          </PubSubContext.Provider>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} position="left" />
      </QueryClientProvider>
    </Provider>
  );
};

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <Main />
    </StrictMode>,
  );
}
