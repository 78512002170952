import {
  Area,
  AreaMultiPolygon,
  AreaType,
  BasicResponse,
  Destination,
  GetAttendanceDetailOfPlantsRTO,
  GetAttendanceDetailOfWorkersRTO,
  GetAttendanceDetailOfWorkersTimeRTO,
  PagedResponse,
  Path,
  PipePath,
} from '../types';
import { AreaAlert, DeviationAlert, RequestAlertsParams } from '../types/Alert';
import { AttendanceLog } from '../types/Attendance';

import { delay } from '../utils/common';

import DsmAxios from './DsmAxios';

export type GetProjectAreasParams = {
  type?: AreaType;
  name?: string;
  id?: string;
  active?: boolean;
  limit?: number;
  prevCursor?: string | null;
  nextCursor?: string | null;
  selectVisible?: boolean;
  simpleSelect?: boolean;
};

export const getAreasOfProject = async (
  projectId?: string,
  params?: GetProjectAreasParams,
) => {
  delay(100);
  return DsmAxios.get<PagedResponse<Area>>(`v1/projects/${projectId}/areas`, {
    params: {
      ...params,
      limit: params?.limit ?? 200,
    },
  });
};

export const createArea = (data: {
  projectId: string;
  payload: {
    name: string;
    area: AreaMultiPolygon | PipePath;
    altitude: number;
    height: number;
    type: AreaType;
    active?: boolean;
    isGPSDisabled?: boolean;
    visible?: boolean;
    paths?: Path;
    destinations?: Destination;
    color?: string;
  };
}) => {
  return DsmAxios.post<BasicResponse<Area>>(
    `v1/projects/${data.projectId}/areas`,
    data.payload,
  );
};

export const updateArea = (data: {
  projectId: string;
  areaId: string;
  payload: {
    name?: string;
    area?: AreaMultiPolygon | PipePath;
    altitude?: number;
    height?: number;
    type?: AreaType;
    active?: boolean;
    isGPSDisabled?: boolean;
    visible?: boolean;
    paths?: Path;
    destinations?: Destination;
    color?: string;
  };
}) => {
  return DsmAxios.patch<BasicResponse<Area>>(
    `v1/projects/${data.projectId}/areas/${data.areaId}`,
    data.payload,
  );
};

export const deleteArea = ({
  projectId,
  areaId,
}: {
  projectId: string;
  areaId: string;
}) => {
  return DsmAxios.delete(`v1/projects/${projectId}/areas/${areaId}`);
};

export const getWhiteListWorker = ({
  projectId,
  areaId,
}: {
  projectId: string;
  areaId: string;
}) => {
  return DsmAxios.get<BasicResponse<Array<string>>>(
    `v1/projects/${projectId}/areas/${areaId}/whitelist-workers`,
  );
};

export const updateListOfWhiteListWorker = ({
  projectId,
  areaId,
  workerIds,
}: {
  projectId: string;
  areaId: string;
  workerIds: Array<string>;
}) => {
  return DsmAxios.put(
    `v1/projects/${projectId}/areas/${areaId}/whitelist-workers`,
    {
      workerIds,
    },
  );
};

export const deleteWhiteListWorker = ({
  projectId,
  areaId,
  workerIds,
}: {
  projectId: string;
  areaId: string;
  workerIds: Array<string>;
}) => {
  return DsmAxios.delete(
    `v1/projects/${projectId}/areas/${areaId}/whitelist-workers`,
    {
      data: {
        workerIds,
      },
    },
  );
};

export const getAttendanceDetailOfWorkers = (
  projectId: string,
  params: {
    fromDate: string;
    toDate: string;
    timeZone?: string;
    interval: 'hour' | 'day';
    dasId?: string;
  },
) => {
  return DsmAxios.get<BasicResponse<GetAttendanceDetailOfWorkersRTO>>(
    `v1/projects/${projectId}/stats/attendance/worker`,
    {
      params,
    },
  );
};

export const getAttendanceDetailOfWorkersTime = (
  projectId: string,
  params: {
    fromDate: string;
    toDate: string;
    timeZone?: string;
    interval: 'hour' | 'day';
    workerId?: string;
    dasId?: string;
  },
) => {
  return DsmAxios.get<BasicResponse<GetAttendanceDetailOfWorkersTimeRTO>>(
    `v1/projects/${projectId}/stats/attendance/worktime`,
    {
      params,
    },
  );
};

export const getAttendanceDetailOfPlants = (
  projectId: string,
  params: {
    fromDate: string;
    toDate: string;
    timeZone?: string;
    interval: 'hour' | 'day';
    dasId?: string;
  },
) => {
  return DsmAxios.get<BasicResponse<GetAttendanceDetailOfPlantsRTO>>(
    `v1/projects/${projectId}/stats/plant-attendance/plant`,
    {
      params,
    },
  );
};

export const getAreaAlerts = (
  projectId: string,
  params?: RequestAlertsParams & {
    workerId?: string;
    type?: AreaType;
    areaId?: string;
    order?: 'asc' | 'desc';
  },
) => {
  return DsmAxios.get<PagedResponse<AreaAlert>>(
    `v1/projects/${projectId}/area-alerts`,
    {
      params,
    },
  );
};

export const readAreaAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(`v1/projects/${projectId}/area-alerts/${alertId}/read`);
};

export const dismissAreaAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/area-alerts/${alertId}/dismiss`,
  );
};

export const undismissAreaAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/area-alerts/${alertId}/undismiss`,
  );
};

export const getDeviationEvents = (
  projectId: string,
  params?: RequestAlertsParams & {
    vehicleId?: string;
    areaId?: string;
    order?: 'asc' | 'desc';
  },
) => {
  return DsmAxios.get<PagedResponse<DeviationAlert>>(
    `v1/projects/${projectId}/deviations`,
    {
      params,
    },
  );
};

export const getLatestDeviationAlerts = (
  projectId: string,
  params?: RequestAlertsParams & {
    vehicleId?: string;
    areaId?: string;
    order?: 'asc' | 'desc';
  },
) => {
  return DsmAxios.get<DeviationAlert>(
    `v1/projects/${projectId}/deviations/latests`,
    {
      params,
    },
  );
};

export const getEnteredExitArea = (
  projectId: string,
  params?: {
    fromDate: string;
    toDate: string;
    timeZone?: string;
    areaId?: string;
    workerId?: string;
    state?: 'all';
    limit?: number;
  },
) => {
  return DsmAxios.get<PagedResponse<AttendanceLog>>(
    `v1/projects/${projectId}/attendances/exited`,
    {
      params,
    },
  );
};

export const getLastEnteredArea = (
  projectId: string,
  params?: {
    fromDate?: string;
    toDate?: string;
    timeZone?: string;
    areaId?: string;
    workerId?: string;
    limit?: number;
    areaType?: AreaType;
  },
) => {
  return DsmAxios.get<PagedResponse<AttendanceLog>>(
    `v1/projects/${projectId}/attendances/last-entered`,
    {
      params,
    },
  );
};

export const getLastEnteredAttendance = (data: {
  projectId: string;
  params?: {
    workerId?: string;
    areaId?: string;
    areaType?: AreaType;
  };
}) => {
  return DsmAxios.get<BasicResponse<AttendanceLog[]>>(
    `v1/projects/${data.projectId}/attendances/last-entered`,
    {
      params: data.params,
    },
  );
};
