// Add domain to allowDomainMap for cross-origin communication
const allowDomainMap = new Map(
  Object.entries({
    'http://localhost:5173': true,
  }),
);

export function initialWindowMessageListener() {
  window.addEventListener(
    'message',
    (event) => {
      if (!allowDomainMap.has(event.origin)) {
        return;
      }

      if (import.meta.env.MODE === 'development') {
        console.info(`DSM -> Message Received`, event.data);
      }

      if (event.data.loginInfo) {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken === event.data.loginInfo.access_token) {
          return;
        }
        try {
          const now = Date.now();
          localStorage.setItem(
            'accessToken',
            event.data.loginInfo.access_token,
          );
          localStorage.setItem(
            'refreshToken',
            event.data.loginInfo.refresh_token,
          );
          localStorage.setItem(
            'expiredDate',
            (now + event.data.loginInfo.expires_in * 1000).toString(),
          );
          localStorage.setItem(
            'refreshTokenExpiredDate',
            (now + event.data.loginInfo.refresh_expires_in * 1000).toString(),
          );

          window.location.reload();

          event.source?.postMessage('DSM -> login successed', {
            targetOrigin: event.origin,
          });
        } catch (e) {
          if (e instanceof Error) {
            console.error(e.message);
          } else {
            console.error('error', e);
          }
        }
      }
    },
    false,
  );
}
