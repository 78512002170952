import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { FeatureListItem, FeatureType, SSSSFeatureList } from '../../types';

import dascasSvg from '../../assets/images/svg/dascasSvg.svg';
import dasloopSvg from '../../assets/images/svg/dasloop.svg';
import plantSvg from '../../assets/images/svg/plant.svg';
import AiCameraSvgIcon from '../../assets/SvgIcon/AiCameraSvgIcon';
import ConfinedSpaceSvgIcon from '../../assets/SvgIcon/ConfinedSpaceSvgIcon';
import DasfindSvgIcon from '../../assets/SvgIcon/DasfindSvgIcon';
import DasLockSvgIcon from '../../assets/SvgIcon/DaslockSvgIcon';
import PermitToWorkSvgIcon from '../../assets/SvgIcon/PermitToWorkSvgIcon';
import VrSvgIcon from '../../assets/SvgIcon/VrSvgIcon';
import IconContainer from '../IconContainer';
import DarkTooltip from '../Toolip/DarkTooltip';

interface SSSSMenuProps {
  dataFeatures: { [key in FeatureType]: FeatureListItem } | undefined;
  selectedProjectId: string | null;
  enterDelay: number;
  expand: boolean | undefined;
}

const SSSSMenu: React.FC<SSSSMenuProps> = ({
  dataFeatures,
  selectedProjectId,
  enterDelay,
  expand,
}) => {
  const { t } = useTranslation('sidebar');

  const ssssFeatureList: SSSSFeatureList | undefined =
    dataFeatures?.four_s?.metadata;

  return (
    <>
      {ssssFeatureList?.DigitisedTrackingSystem && (
        <NavLink
          to={`/asset-management-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dasfind"
        >
          <DarkTooltip
            title={t('tooltip.ssss.DigitisedTrackingSystem')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <DasfindSvgIcon />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.ssss.DigitisedTrackingSystem')}</span>}
        </NavLink>
      )}
      {ssssFeatureList?.DigitalisedPermitToWorkSystem && (
        <NavLink
          to={`/permit-to-work-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-permit-to-work"
        >
          <DarkTooltip
            title={t('tooltip.ssss.DigitalisedPermitToWorkSystem')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <PermitToWorkSvgIcon />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && (
            <span>{t('tooltip.ssss.DigitalisedPermitToWorkSystem')}</span>
          )}
        </NavLink>
      )}
      {ssssFeatureList?.HazardousAreasAccessControl && (
        <NavLink
          to={`/daslock-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-daslock"
        >
          <DarkTooltip
            title={t('tooltip.ssss.HazardousAreasAccessControl')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <DasLockSvgIcon />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && (
            <span>{t('tooltip.ssss.HazardousAreasAccessControl')}</span>
          )}
        </NavLink>
      )}
      {ssssFeatureList?.[
        'UnsafeActs/DangerousSituationAlertForMobilePlantOperationDangerZone'
      ] && (
        <NavLink
          to={`/dastrack-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dastrack"
        >
          <DarkTooltip
            title={t(
              'tooltip.ssss.UnsafeActs/DangerousSituationAlertForMobilePlantOperationDangerZone',
            )}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={plantSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && (
            <span>
              {t(
                'tooltip.ssss.UnsafeActs/DangerousSituationAlertForMobilePlantOperationDangerZone',
              )}
            </span>
          )}
        </NavLink>
      )}
      {ssssFeatureList?.[
        'UnsafeActs/DangerousSituationAlertForTowerCraneLiftingZone'
      ] && (
        <NavLink
          to={`/dascas-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dascas"
        >
          <DarkTooltip
            title={t(
              'tooltip.ssss.UnsafeActs/DangerousSituationAlertForTowerCraneLiftingZone',
            )}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={dascasSvg} alt="DasCAS" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && (
            <span>
              {t(
                'tooltip.ssss.UnsafeActs/DangerousSituationAlertForTowerCraneLiftingZone',
              )}
            </span>
          )}
        </NavLink>
      )}
      {ssssFeatureList?.SmartMonitoringDevices && (
        <NavLink
          to={`/dasloop-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dasloop"
        >
          <DarkTooltip
            title={t('tooltip.ssss.SmartMonitoringDevices')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <img src={dasloopSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.ssss.SmartMonitoringDevices')}</span>}
        </NavLink>
      )}
      {ssssFeatureList?.SafetyMonitoringSystem && (
        <NavLink
          to={`/safety-monitoring-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dasloop"
        >
          <DarkTooltip
            title={t('tooltip.ssss.SafetyMonitoringSystem')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <AiCameraSvgIcon />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.ssss.SafetyMonitoringSystem')}</span>}
        </NavLink>
      )}
      {ssssFeatureList?.ConfinedSpaceMonitoringSystem && (
        <NavLink
          to={`/confined-space-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dasloop"
        >
          <DarkTooltip
            title={t('tooltip.ssss.ConfinedSpaceMonitoringSystem')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <ConfinedSpaceSvgIcon />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && (
            <span>{t('tooltip.ssss.ConfinedSpaceMonitoringSystem')}</span>
          )}
        </NavLink>
      )}
      {ssssFeatureList?.SafetyTrainingWithVirtualRealityTechnology && (
        <NavLink
          to={`/safety-training-vr-dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-dasloop"
        >
          <DarkTooltip
            title={t('tooltip.ssss.SafetyTrainingWithVirtualRealityTechnology')}
            placement="right"
            enterDelay={enterDelay}
          >
            <div>
              <IconContainer className="icon">
                <VrSvgIcon />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && (
            <span>
              {t('tooltip.ssss.SafetyTrainingWithVirtualRealityTechnology')}
            </span>
          )}
        </NavLink>
      )}
    </>
  );
};

export default SSSSMenu;
